const memberTestData = [
  {
    id: 1,
    name: "홍길동",
    position: "프론트엔드 개발자",
    gender: "남성",
    location: "서울",
    introduction:
      "React와 JavaScript 경험이 있는 열정적인 프론트엔드 개발자입니다.",
    skill1: "React",
    skill2: "JavaScript",
    occupationClassifications: [],
  },
  {
    id: 2,
    name: "이영희",
    position: "백엔드 개발자",
    gender: "여성",
    location: "부산",
    introduction: "Node.js와 MongoDB에 능숙한 백엔드 개발자입니다.",
    skill1: "Node.js",
    skill2: "MongoDB",
    occupationClassifications: [],
  },
  {
    id: 3,
    name: "김철수",
    position: "풀스택 개발자",
    gender: "남성",
    location: "인천",
    introduction: "프론트엔드와 백엔드 기술에 능숙한 풀스택 개발자입니다.",
    skill1: "React",
    skill2: "Node.js",
    occupationClassifications: [],
  },
  {
    id: 4,
    name: "박영미",
    position: "UI/UX 디자이너",
    gender: "여성",
    location: "광주",
    introduction:
      "사용자 중심 설계에 열정을 가진 창의적인 UI/UX 디자이너입니다.",
    skill1: "Adobe XD",
    skill2: "Figma",
    occupationClassifications: [],
  },
  {
    id: 5,
    name: "정영호",
    position: "모바일 개발자",
    gender: "남성",
    location: "대전",
    introduction: "iOS와 Android 개발에 능숙한 경험 많은 모바일 개발자입니다.",
    skill1: "iOS",
    skill2: "Android",
    occupationClassifications: [],
  },
  {
    id: 6,
    name: "이수진",
    position: "데이터 과학자",
    gender: "여성",
    location: "울산",
    introduction:
      "머신러닝과 데이터 분석에 대한 배경을 가진 데이터 과학자입니다.",
    skill1: "Python",
    skill2: "TensorFlow",
    occupationClassifications: [],
  },
];

export default memberTestData;
