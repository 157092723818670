export const competitions = [
  {
    title: "서울교육 데이터 활용 아이디어 공모전",
    organizer: "서울특별시교육청",
    deadline: "2024.05.15",
    views: 1120,
    state: 0,
  },
  {
    title: "빅데이터 분석 대회",
    organizer: "서울시",
    deadline: "2024.06.01",
    views: 980,
    state: 1,
  },
  {
    title: "인공지능 아이디어 공모전",
    organizer: "서울대학교",
    deadline: "2024.07.20",
    views: 1500,
    state: 2,
  },
  {
    title: "데이터 시각화 경진대회",
    organizer: "한국데이터산업진흥원",
    deadline: "2024.08.10",
    views: 1120,
    state: 0,
  },
  {
    title: "IoT 활용 공모전",
    organizer: "과학기술정보통신부",
    deadline: "2024.09.15",
    views: 1340,
    state: 1,
  },
  {
    title: "스마트시티 해커톤",
    organizer: "국토교통부",
    deadline: "2024.10.05",
    views: 1230,
    state: 2,
  },
  {
    title: "AI 챌린지",
    organizer: "카이스트",
    deadline: "2024.11.25",
    views: 800,
    state: 0,
  },
  {
    title: "청소년 데이터 사이언스 대회",
    organizer: "서울시교육청",
    deadline: "2024.12.10",
    views: 1100,
    state: 1,
  },
  {
    title: "의료 데이터 분석 공모전",
    organizer: "보건복지부",
    deadline: "2025.01.05",
    views: 1450,
    state: 2,
  },
  {
    title: "교통 데이터 활용 경진대회",
    organizer: "서울특별시",
    deadline: "2025.02.20",
    views: 1300,
    state: 0,
  },
  {
    title: "에너지 빅데이터 공모전",
    organizer: "산업통상자원부",
    deadline: "2025.03.15",
    views: 1250,
    state: 1,
  },
  {
    title: "환경 데이터 분석 대회",
    organizer: "환경부",
    deadline: "2025.04.10",
    views: 1400,
    state: 2,
  },
  {
    title: "스포츠 데이터 챌린지",
    organizer: "대한체육회",
    deadline: "2025.05.20",
    views: 900,
    state: 0,
  },
  {
    title: "금융 데이터 해커톤",
    organizer: "금융위원회",
    deadline: "2025.06.25",
    views: 1100,
    state: 1,
  },
  {
    title: "소셜미디어 데이터 분석 공모전",
    organizer: "네이버",
    deadline: "2025.07.15",
    views: 1600,
    state: 2,
  },
  {
    title: "게임 데이터 분석 대회",
    organizer: "한국게임산업협회",
    deadline: "2025.08.10",
    views: 1000,
    state: 0,
  },
  {
    title: "드론 데이터 활용 경진대회",
    organizer: "한국항공우주연구원",
    deadline: "2025.09.05",
    views: 1200,
    state: 1,
  },
  {
    title: "스마트팜 데이터 챌린지",
    organizer: "농림축산식품부",
    deadline: "2025.10.20",
    views: 1500,
    state: 2,
  },
  {
    title: "공공 데이터 활용 아이디어 공모전",
    organizer: "행정안전부",
    deadline: "2025.11.15",
    views: 1700,
    state: 0,
  },
  {
    title: "빅데이터 스타트업 경진대회",
    organizer: "중소벤처기업부",
    deadline: "2025.12.01",
    views: 1900,
    state: 1,
  },
];
