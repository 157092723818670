export const competitionData = {
  title: "대외활동 제목",
  image:
    "https://www.shinyoungfoundation.org/data/editor/2303/00b414bab3412b76b281038a72eb204c_1679453932_5953.jpg",
  field: "분야",
  organizer: "주최 기관",
  target: "응모대상",
  tag: "개발",
  period: "총 기간",
  prize: "총 상금",
  website: "홈페이지",
  file: "첨부파일",
  description: `2024 ACC 민주·인권·평화 웹툰 공모전

  1980년 5·18민주화운동의 최후 항쟁지 옛 전남도청 일원에 위치한 국립아시아문화전당은 민주·인권·평화의 가치를 문화예술로 승화하여 세계인과 공유해 오고 있습니다. 민주·인권·평화의 가치 확산 및 5·18민주화운동을 기념하고자 을 진행합니다. 여러분들의 많은 관심과 참여 바랍니다.
  
  ■ 공모주제
  1) 국내 민주, 인권, 평화와 관련된 역사적 사건 소재로 긍정적 가치 전달
  2) 5·18민주화운동 최후의 항쟁지로써의 ACC의 의미와 소개
  ※ 2개 주제 중 선택하여 응모
  
  ■ 공모기간
  - 2024. 4. 1.(월) ~ 2024. 6. 16.(일) ※ 접수기간 동일
  
  ■ 응모자격
  - 누구나(개인 또는 팀)
  ※ 팀은 5인 이내 참여 가능, 1인(팀)당 출품작 수 제한 없으나, 중복 시상은 불가함
  
  ■ 응모부분
  1) (청년·일반부)만 19세 이상 누구나 ※2005년 6월 16일 이전(포함) 출생자
  2) (유아·어린이·청소년부)만 18세 이하 누구나 ※2005년 6월 17일 이후(포함) 출생자
  ※ 공모기간 종료일(2024.6.16.) 기준이며, 팀의 경우 팀내 최연장자 나이 기준 적용
  
  ■ 작품형식
  1) < 2023 ACC 민주·인권·평화 캐릭터 공모전 > 대상 수상 캐릭터를 활용한 웹툰
   ※ 청년·일반부 대상 [민주동이, 인권동이, 평화동이]/유아·어린이·청소년부 대상 [인절미, 오일이, 일팔이] 활용, 자세한 캐릭터 확인은 [참고] 또는 ACC 누리집 확인
  2 )응모 참여자(팀)의 순수 창작 웹툰
   ※ 2개 형식 중 선택하여 응모
  
  ■ 작품 규격
  - 스트롤 뷰
    * 웹 게재용 스크롤 형식
    * 10스크롤 이상 단편 웹툰 완성작(완결)
    * 가로 720px, 세로 제한 없음
    * 해상도 300dpi,
    * jpg, png 파일 제출
  - 컷 뷰
    * 웹 게재용 정사각형 비율
    * 10컷 이상 단편 웹툰 완성작(완결)
    * 가로×세로, 720px×720px
    * 해상도 300dpi
    * jpg, png 파일 제출
  ※ 수기작품(손그림 등) 및 디지털 작업 모두 가능, 수기작품(손그림)의 경우 스캔하여 제출, 수상작은 추후 원본 파일 제출(AI, PSD, 손그림 원본 등)
  ※ 수상하는 작품(디지털 작업 등)은 레이어 분리된 파일 제출(AI, PSD 등)
  
  ■ 시상내역: 총 20작품 선정 / 총 상금 18백만원
  - 청년·일반부(10)
   * 대    상 : 1명(팀) / 문체부장관상 / 500만원 
   * 최우수상 : 1명(팀) / 전당장상 / 200만원 
   * 우 수 상 : 3명(팀) / 전당장상 / 각 100만원
   * 장 려 상 : 5명(팀) / 전당장상 / 각 50만원
  - 유아·어린이·청소년부(10)
   * 대    상 : 1명(팀) / 문체부장관상 / 200만원
   * 최우수상 : 1명(팀) / 전당장상 / 100만원
   * 우 수 상 : 3명(팀) / 전당장상 / 각 50만원
   * 장 려 상 : 5명(팀) / 전당장상 / 각 20만원
  
  ■ 문의
  - 국립아시아문화전당 교류홍보과 062-601-4264 / 52songa@korea.kr
  ※ 평일10:00~17:00(휴게시간 11:30~13:00 제외, 주말 및 공휴일 문의 불가) 이외 시간은 메일로 문의
  공모전 대학생대외활동 사이트 : 위비티 https://www.wevity.com`,
};
