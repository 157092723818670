const MyResumeTestData = [
  {
    name: "이름",
    gender: "성별",
    age: "나이",
    belong: "소속",
    region: "지역",
    introduction: "한줄 소개가나다라마바사",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
  {
    name: "최태호",
    gender: "남",
    age: "19",
    belong: "국민대학교",
    region: "서울",
    introduction: "풂풂",
  },
];

export default MyResumeTestData;
