export const jobCategories = [
  {
    kind: "design",
    mainCategory: "디자인",
    subCategories: [
      "UI/UX 디자이너",
      "일러스트/캐릭터 디자이너",
      "영상/모션 그래픽 디자이너",
      "제품/패키지 디자이너",
      "인테리어 디자이너",
      "패션 디자이너",
      "공예",
      "그래픽 디자이너",
    ],
  },
  {
    kind: "development",
    mainCategory: "개발",
    subCategories: [
      "프론트엔드",
      "백엔드",
      "AI 데이터 엔지니어",
      "데브옵스 개발자",
      "보안 전문가",
      "게임 개발자",
      "임베디드 개발",
      "DBA",
    ],
  },
  {
    kind: "planning",
    mainCategory: "기획",
    subCategories: ["기획"],
  },
  {
    kind: "media",
    mainCategory: "미디어",
    subCategories: [
      "영상 편집",
      "영상 촬영",
      "PD",
      "사진 작가",
      "음향 엔지니어",
    ],
  },
  {
    kind: "marketing",
    mainCategory: "마케팅",
    subCategories: ["관리", "홍보", "컨설팅"],
  },
  {
    kind: "translation",
    mainCategory: "통번역",
    subCategories: [
      "영어",
      "일본어",
      "중국어",
      "스페인어",
      "아랍어",
      "힌디어",
      "프랑스어",
      "기타",
    ],
  },
  {
    kind: "other",
    mainCategory: "기타",
    subCategories: ["기타"],
  },
];
